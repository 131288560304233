// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-amex-name: 'sprite-amex';
$sprite-amex-x: 0px;
$sprite-amex-y: 307px;
$sprite-amex-offset-x: 0px;
$sprite-amex-offset-y: -307px;
$sprite-amex-width: 51px;
$sprite-amex-height: 36px;
$sprite-amex-total-width: 205px;
$sprite-amex-total-height: 727px;
$sprite-amex-image: '../staticImages/img-sprite.png';
$sprite-amex: (0px, 307px, 0px, -307px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-amex', );
$sprite-creditcard-name: 'sprite-creditcard';
$sprite-creditcard-x: 0px;
$sprite-creditcard-y: 62px;
$sprite-creditcard-offset-x: 0px;
$sprite-creditcard-offset-y: -62px;
$sprite-creditcard-width: 51px;
$sprite-creditcard-height: 35px;
$sprite-creditcard-total-width: 205px;
$sprite-creditcard-total-height: 727px;
$sprite-creditcard-image: '../staticImages/img-sprite.png';
$sprite-creditcard: (0px, 62px, 0px, -62px, 51px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-creditcard', );
$sprite-creditcards-wide-name: 'sprite-creditcards-wide';
$sprite-creditcards-wide-x: 0px;
$sprite-creditcards-wide-y: 40px;
$sprite-creditcards-wide-offset-x: 0px;
$sprite-creditcards-wide-offset-y: -40px;
$sprite-creditcards-wide-width: 110px;
$sprite-creditcards-wide-height: 22px;
$sprite-creditcards-wide-total-width: 205px;
$sprite-creditcards-wide-total-height: 727px;
$sprite-creditcards-wide-image: '../staticImages/img-sprite.png';
$sprite-creditcards-wide: (0px, 40px, 0px, -40px, 110px, 22px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-creditcards-wide', );
$sprite-dhl-name: 'sprite-dhl';
$sprite-dhl-x: 0px;
$sprite-dhl-y: 19px;
$sprite-dhl-offset-x: 0px;
$sprite-dhl-offset-y: -19px;
$sprite-dhl-width: 119px;
$sprite-dhl-height: 21px;
$sprite-dhl-total-width: 205px;
$sprite-dhl-total-height: 727px;
$sprite-dhl-image: '../staticImages/img-sprite.png';
$sprite-dhl: (0px, 19px, 0px, -19px, 119px, 21px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-dhl', );
$sprite-forecast-dhl-name: 'sprite-forecast-dhl';
$sprite-forecast-dhl-x: 0px;
$sprite-forecast-dhl-y: 97px;
$sprite-forecast-dhl-offset-x: 0px;
$sprite-forecast-dhl-offset-y: -97px;
$sprite-forecast-dhl-width: 51px;
$sprite-forecast-dhl-height: 35px;
$sprite-forecast-dhl-total-width: 205px;
$sprite-forecast-dhl-total-height: 727px;
$sprite-forecast-dhl-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl: (0px, 97px, 0px, -97px, 51px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl', );
$sprite-forecast-dhl-eu-name: 'sprite-forecast-dhl_eu';
$sprite-forecast-dhl-eu-x: 0px;
$sprite-forecast-dhl-eu-y: 132px;
$sprite-forecast-dhl-eu-offset-x: 0px;
$sprite-forecast-dhl-eu-offset-y: -132px;
$sprite-forecast-dhl-eu-width: 51px;
$sprite-forecast-dhl-eu-height: 35px;
$sprite-forecast-dhl-eu-total-width: 205px;
$sprite-forecast-dhl-eu-total-height: 727px;
$sprite-forecast-dhl-eu-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl-eu: (0px, 132px, 0px, -132px, 51px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl_eu', );
$sprite-forecast-dhl-express-name: 'sprite-forecast-dhl_express';
$sprite-forecast-dhl-express-x: 0px;
$sprite-forecast-dhl-express-y: 167px;
$sprite-forecast-dhl-express-offset-x: 0px;
$sprite-forecast-dhl-express-offset-y: -167px;
$sprite-forecast-dhl-express-width: 51px;
$sprite-forecast-dhl-express-height: 35px;
$sprite-forecast-dhl-express-total-width: 205px;
$sprite-forecast-dhl-express-total-height: 727px;
$sprite-forecast-dhl-express-image: '../staticImages/img-sprite.png';
$sprite-forecast-dhl-express: (0px, 167px, 0px, -167px, 51px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-forecast-dhl_express', );
$sprite-forecast-hermes-name: 'sprite-forecast-hermes';
$sprite-forecast-hermes-x: 0px;
$sprite-forecast-hermes-y: 202px;
$sprite-forecast-hermes-offset-x: 0px;
$sprite-forecast-hermes-offset-y: -202px;
$sprite-forecast-hermes-width: 51px;
$sprite-forecast-hermes-height: 35px;
$sprite-forecast-hermes-total-width: 205px;
$sprite-forecast-hermes-total-height: 727px;
$sprite-forecast-hermes-image: '../staticImages/img-sprite.png';
$sprite-forecast-hermes: (0px, 202px, 0px, -202px, 51px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-forecast-hermes', );
$sprite-forecast-wein-service-bonn-name: 'sprite-forecast-wein_service_bonn';
$sprite-forecast-wein-service-bonn-x: 0px;
$sprite-forecast-wein-service-bonn-y: 343px;
$sprite-forecast-wein-service-bonn-offset-x: 0px;
$sprite-forecast-wein-service-bonn-offset-y: -343px;
$sprite-forecast-wein-service-bonn-width: 51px;
$sprite-forecast-wein-service-bonn-height: 36px;
$sprite-forecast-wein-service-bonn-total-width: 205px;
$sprite-forecast-wein-service-bonn-total-height: 727px;
$sprite-forecast-wein-service-bonn-image: '../staticImages/img-sprite.png';
$sprite-forecast-wein-service-bonn: (0px, 343px, 0px, -343px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-forecast-wein_service_bonn', );
$sprite-giftvoucher-name: 'sprite-giftvoucher';
$sprite-giftvoucher-x: 0px;
$sprite-giftvoucher-y: 379px;
$sprite-giftvoucher-offset-x: 0px;
$sprite-giftvoucher-offset-y: -379px;
$sprite-giftvoucher-width: 51px;
$sprite-giftvoucher-height: 36px;
$sprite-giftvoucher-total-width: 205px;
$sprite-giftvoucher-total-height: 727px;
$sprite-giftvoucher-image: '../staticImages/img-sprite.png';
$sprite-giftvoucher: (0px, 379px, 0px, -379px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-giftvoucher', );
$sprite-hermes-name: 'sprite-hermes';
$sprite-hermes-x: 0px;
$sprite-hermes-y: 237px;
$sprite-hermes-offset-x: 0px;
$sprite-hermes-offset-y: -237px;
$sprite-hermes-width: 51px;
$sprite-hermes-height: 35px;
$sprite-hermes-total-width: 205px;
$sprite-hermes-total-height: 727px;
$sprite-hermes-image: '../staticImages/img-sprite.png';
$sprite-hermes: (0px, 237px, 0px, -237px, 51px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-hermes', );
$sprite-hermeslogo-name: 'sprite-hermeslogo';
$sprite-hermeslogo-x: 0px;
$sprite-hermeslogo-y: 272px;
$sprite-hermeslogo-offset-x: 0px;
$sprite-hermeslogo-offset-y: -272px;
$sprite-hermeslogo-width: 80px;
$sprite-hermeslogo-height: 35px;
$sprite-hermeslogo-total-width: 205px;
$sprite-hermeslogo-total-height: 727px;
$sprite-hermeslogo-image: '../staticImages/img-sprite.png';
$sprite-hermeslogo: (0px, 272px, 0px, -272px, 80px, 35px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-hermeslogo', );
$sprite-hermespaketshop-name: 'sprite-hermespaketshop';
$sprite-hermespaketshop-x: 0px;
$sprite-hermespaketshop-y: 676px;
$sprite-hermespaketshop-offset-x: 0px;
$sprite-hermespaketshop-offset-y: -676px;
$sprite-hermespaketshop-width: 62px;
$sprite-hermespaketshop-height: 51px;
$sprite-hermespaketshop-total-width: 205px;
$sprite-hermespaketshop-total-height: 727px;
$sprite-hermespaketshop-image: '../staticImages/img-sprite.png';
$sprite-hermespaketshop: (0px, 676px, 0px, -676px, 62px, 51px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-hermespaketshop', );
$sprite-mastercard-name: 'sprite-mastercard';
$sprite-mastercard-x: 0px;
$sprite-mastercard-y: 415px;
$sprite-mastercard-offset-x: 0px;
$sprite-mastercard-offset-y: -415px;
$sprite-mastercard-width: 51px;
$sprite-mastercard-height: 36px;
$sprite-mastercard-total-width: 205px;
$sprite-mastercard-total-height: 727px;
$sprite-mastercard-image: '../staticImages/img-sprite.png';
$sprite-mastercard: (0px, 415px, 0px, -415px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-mastercard', );
$sprite-packstation-name: 'sprite-packstation';
$sprite-packstation-x: 0px;
$sprite-packstation-y: 0px;
$sprite-packstation-offset-x: 0px;
$sprite-packstation-offset-y: 0px;
$sprite-packstation-width: 87px;
$sprite-packstation-height: 19px;
$sprite-packstation-total-width: 205px;
$sprite-packstation-total-height: 727px;
$sprite-packstation-image: '../staticImages/img-sprite.png';
$sprite-packstation: (0px, 0px, 0px, 0px, 87px, 19px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-packstation', );
$sprite-paypal-name: 'sprite-paypal';
$sprite-paypal-x: 0px;
$sprite-paypal-y: 451px;
$sprite-paypal-offset-x: 0px;
$sprite-paypal-offset-y: -451px;
$sprite-paypal-width: 51px;
$sprite-paypal-height: 36px;
$sprite-paypal-total-width: 205px;
$sprite-paypal-total-height: 727px;
$sprite-paypal-image: '../staticImages/img-sprite.png';
$sprite-paypal: (0px, 451px, 0px, -451px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-paypal', );
$sprite-paypalcheckout-name: 'sprite-paypalcheckout';
$sprite-paypalcheckout-x: 0px;
$sprite-paypalcheckout-y: 632px;
$sprite-paypalcheckout-offset-x: 0px;
$sprite-paypalcheckout-offset-y: -632px;
$sprite-paypalcheckout-width: 205px;
$sprite-paypalcheckout-height: 44px;
$sprite-paypalcheckout-total-width: 205px;
$sprite-paypalcheckout-total-height: 727px;
$sprite-paypalcheckout-image: '../staticImages/img-sprite.png';
$sprite-paypalcheckout: (0px, 632px, 0px, -632px, 205px, 44px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-paypalcheckout', );
$sprite-paypalexpress-name: 'sprite-paypalexpress';
$sprite-paypalexpress-x: 0px;
$sprite-paypalexpress-y: 595px;
$sprite-paypalexpress-offset-x: 0px;
$sprite-paypalexpress-offset-y: -595px;
$sprite-paypalexpress-width: 64px;
$sprite-paypalexpress-height: 37px;
$sprite-paypalexpress-total-width: 205px;
$sprite-paypalexpress-total-height: 727px;
$sprite-paypalexpress-image: '../staticImages/img-sprite.png';
$sprite-paypalexpress: (0px, 595px, 0px, -595px, 64px, 37px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-paypalexpress', );
$sprite-rechnung-name: 'sprite-rechnung';
$sprite-rechnung-x: 0px;
$sprite-rechnung-y: 487px;
$sprite-rechnung-offset-x: 0px;
$sprite-rechnung-offset-y: -487px;
$sprite-rechnung-width: 51px;
$sprite-rechnung-height: 36px;
$sprite-rechnung-total-width: 205px;
$sprite-rechnung-total-height: 727px;
$sprite-rechnung-image: '../staticImages/img-sprite.png';
$sprite-rechnung: (0px, 487px, 0px, -487px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-rechnung', );
$sprite-sepa-name: 'sprite-sepa';
$sprite-sepa-x: 0px;
$sprite-sepa-y: 523px;
$sprite-sepa-offset-x: 0px;
$sprite-sepa-offset-y: -523px;
$sprite-sepa-width: 51px;
$sprite-sepa-height: 36px;
$sprite-sepa-total-width: 205px;
$sprite-sepa-total-height: 727px;
$sprite-sepa-image: '../staticImages/img-sprite.png';
$sprite-sepa: (0px, 523px, 0px, -523px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-sepa', );
$sprite-visa-name: 'sprite-visa';
$sprite-visa-x: 0px;
$sprite-visa-y: 559px;
$sprite-visa-offset-x: 0px;
$sprite-visa-offset-y: -559px;
$sprite-visa-width: 51px;
$sprite-visa-height: 36px;
$sprite-visa-total-width: 205px;
$sprite-visa-total-height: 727px;
$sprite-visa-image: '../staticImages/img-sprite.png';
$sprite-visa: (0px, 559px, 0px, -559px, 51px, 36px, 205px, 727px, '../staticImages/img-sprite.png', 'sprite-visa', );
$sprite-amex-2x-name: 'sprite-amex-2x';
$sprite-amex-2x-x: 0px;
$sprite-amex-2x-y: 614px;
$sprite-amex-2x-offset-x: 0px;
$sprite-amex-2x-offset-y: -614px;
$sprite-amex-2x-width: 102px;
$sprite-amex-2x-height: 72px;
$sprite-amex-2x-total-width: 410px;
$sprite-amex-2x-total-height: 1454px;
$sprite-amex-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-amex-2x: (0px, 614px, 0px, -614px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-amex-2x', );
$sprite-creditcard-2x-name: 'sprite-creditcard-2x';
$sprite-creditcard-2x-x: 0px;
$sprite-creditcard-2x-y: 124px;
$sprite-creditcard-2x-offset-x: 0px;
$sprite-creditcard-2x-offset-y: -124px;
$sprite-creditcard-2x-width: 102px;
$sprite-creditcard-2x-height: 70px;
$sprite-creditcard-2x-total-width: 410px;
$sprite-creditcard-2x-total-height: 1454px;
$sprite-creditcard-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-creditcard-2x: (0px, 124px, 0px, -124px, 102px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-creditcard-2x', );
$sprite-creditcards-wide-2x-name: 'sprite-creditcards-wide-2x';
$sprite-creditcards-wide-2x-x: 0px;
$sprite-creditcards-wide-2x-y: 80px;
$sprite-creditcards-wide-2x-offset-x: 0px;
$sprite-creditcards-wide-2x-offset-y: -80px;
$sprite-creditcards-wide-2x-width: 220px;
$sprite-creditcards-wide-2x-height: 44px;
$sprite-creditcards-wide-2x-total-width: 410px;
$sprite-creditcards-wide-2x-total-height: 1454px;
$sprite-creditcards-wide-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-creditcards-wide-2x: (0px, 80px, 0px, -80px, 220px, 44px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-creditcards-wide-2x', );
$sprite-dhl-2x-name: 'sprite-dhl-2x';
$sprite-dhl-2x-x: 0px;
$sprite-dhl-2x-y: 38px;
$sprite-dhl-2x-offset-x: 0px;
$sprite-dhl-2x-offset-y: -38px;
$sprite-dhl-2x-width: 238px;
$sprite-dhl-2x-height: 42px;
$sprite-dhl-2x-total-width: 410px;
$sprite-dhl-2x-total-height: 1454px;
$sprite-dhl-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-dhl-2x: (0px, 38px, 0px, -38px, 238px, 42px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-dhl-2x', );
$sprite-forecast-dhl-2x-name: 'sprite-forecast-dhl-2x';
$sprite-forecast-dhl-2x-x: 0px;
$sprite-forecast-dhl-2x-y: 194px;
$sprite-forecast-dhl-2x-offset-x: 0px;
$sprite-forecast-dhl-2x-offset-y: -194px;
$sprite-forecast-dhl-2x-width: 102px;
$sprite-forecast-dhl-2x-height: 70px;
$sprite-forecast-dhl-2x-total-width: 410px;
$sprite-forecast-dhl-2x-total-height: 1454px;
$sprite-forecast-dhl-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-2x: (0px, 194px, 0px, -194px, 102px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl-2x', );
$sprite-forecast-dhl-eu-2x-name: 'sprite-forecast-dhl_eu-2x';
$sprite-forecast-dhl-eu-2x-x: 0px;
$sprite-forecast-dhl-eu-2x-y: 264px;
$sprite-forecast-dhl-eu-2x-offset-x: 0px;
$sprite-forecast-dhl-eu-2x-offset-y: -264px;
$sprite-forecast-dhl-eu-2x-width: 102px;
$sprite-forecast-dhl-eu-2x-height: 70px;
$sprite-forecast-dhl-eu-2x-total-width: 410px;
$sprite-forecast-dhl-eu-2x-total-height: 1454px;
$sprite-forecast-dhl-eu-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-eu-2x: (0px, 264px, 0px, -264px, 102px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl_eu-2x', );
$sprite-forecast-dhl-express-2x-name: 'sprite-forecast-dhl_express-2x';
$sprite-forecast-dhl-express-2x-x: 0px;
$sprite-forecast-dhl-express-2x-y: 334px;
$sprite-forecast-dhl-express-2x-offset-x: 0px;
$sprite-forecast-dhl-express-2x-offset-y: -334px;
$sprite-forecast-dhl-express-2x-width: 102px;
$sprite-forecast-dhl-express-2x-height: 70px;
$sprite-forecast-dhl-express-2x-total-width: 410px;
$sprite-forecast-dhl-express-2x-total-height: 1454px;
$sprite-forecast-dhl-express-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-dhl-express-2x: (0px, 334px, 0px, -334px, 102px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-dhl_express-2x', );
$sprite-forecast-hermes-2x-name: 'sprite-forecast-hermes-2x';
$sprite-forecast-hermes-2x-x: 0px;
$sprite-forecast-hermes-2x-y: 404px;
$sprite-forecast-hermes-2x-offset-x: 0px;
$sprite-forecast-hermes-2x-offset-y: -404px;
$sprite-forecast-hermes-2x-width: 102px;
$sprite-forecast-hermes-2x-height: 70px;
$sprite-forecast-hermes-2x-total-width: 410px;
$sprite-forecast-hermes-2x-total-height: 1454px;
$sprite-forecast-hermes-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-hermes-2x: (0px, 404px, 0px, -404px, 102px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-hermes-2x', );
$sprite-forecast-wein-service-bonn-2x-name: 'sprite-forecast-wein_service_bonn-2x';
$sprite-forecast-wein-service-bonn-2x-x: 0px;
$sprite-forecast-wein-service-bonn-2x-y: 686px;
$sprite-forecast-wein-service-bonn-2x-offset-x: 0px;
$sprite-forecast-wein-service-bonn-2x-offset-y: -686px;
$sprite-forecast-wein-service-bonn-2x-width: 102px;
$sprite-forecast-wein-service-bonn-2x-height: 72px;
$sprite-forecast-wein-service-bonn-2x-total-width: 410px;
$sprite-forecast-wein-service-bonn-2x-total-height: 1454px;
$sprite-forecast-wein-service-bonn-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-forecast-wein-service-bonn-2x: (0px, 686px, 0px, -686px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-forecast-wein_service_bonn-2x', );
$sprite-giftvoucher-2x-name: 'sprite-giftvoucher-2x';
$sprite-giftvoucher-2x-x: 0px;
$sprite-giftvoucher-2x-y: 758px;
$sprite-giftvoucher-2x-offset-x: 0px;
$sprite-giftvoucher-2x-offset-y: -758px;
$sprite-giftvoucher-2x-width: 102px;
$sprite-giftvoucher-2x-height: 72px;
$sprite-giftvoucher-2x-total-width: 410px;
$sprite-giftvoucher-2x-total-height: 1454px;
$sprite-giftvoucher-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-giftvoucher-2x: (0px, 758px, 0px, -758px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-giftvoucher-2x', );
$sprite-hermes-2x-name: 'sprite-hermes-2x';
$sprite-hermes-2x-x: 0px;
$sprite-hermes-2x-y: 474px;
$sprite-hermes-2x-offset-x: 0px;
$sprite-hermes-2x-offset-y: -474px;
$sprite-hermes-2x-width: 102px;
$sprite-hermes-2x-height: 70px;
$sprite-hermes-2x-total-width: 410px;
$sprite-hermes-2x-total-height: 1454px;
$sprite-hermes-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermes-2x: (0px, 474px, 0px, -474px, 102px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-hermes-2x', );
$sprite-hermeslogo-2x-name: 'sprite-hermeslogo-2x';
$sprite-hermeslogo-2x-x: 0px;
$sprite-hermeslogo-2x-y: 544px;
$sprite-hermeslogo-2x-offset-x: 0px;
$sprite-hermeslogo-2x-offset-y: -544px;
$sprite-hermeslogo-2x-width: 160px;
$sprite-hermeslogo-2x-height: 70px;
$sprite-hermeslogo-2x-total-width: 410px;
$sprite-hermeslogo-2x-total-height: 1454px;
$sprite-hermeslogo-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermeslogo-2x: (0px, 544px, 0px, -544px, 160px, 70px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-hermeslogo-2x', );
$sprite-hermespaketshop-2x-name: 'sprite-hermespaketshop-2x';
$sprite-hermespaketshop-2x-x: 0px;
$sprite-hermespaketshop-2x-y: 1352px;
$sprite-hermespaketshop-2x-offset-x: 0px;
$sprite-hermespaketshop-2x-offset-y: -1352px;
$sprite-hermespaketshop-2x-width: 124px;
$sprite-hermespaketshop-2x-height: 102px;
$sprite-hermespaketshop-2x-total-width: 410px;
$sprite-hermespaketshop-2x-total-height: 1454px;
$sprite-hermespaketshop-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-hermespaketshop-2x: (0px, 1352px, 0px, -1352px, 124px, 102px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-hermespaketshop-2x', );
$sprite-mastercard-2x-name: 'sprite-mastercard-2x';
$sprite-mastercard-2x-x: 0px;
$sprite-mastercard-2x-y: 830px;
$sprite-mastercard-2x-offset-x: 0px;
$sprite-mastercard-2x-offset-y: -830px;
$sprite-mastercard-2x-width: 102px;
$sprite-mastercard-2x-height: 72px;
$sprite-mastercard-2x-total-width: 410px;
$sprite-mastercard-2x-total-height: 1454px;
$sprite-mastercard-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-mastercard-2x: (0px, 830px, 0px, -830px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-mastercard-2x', );
$sprite-packstation-2x-name: 'sprite-packstation-2x';
$sprite-packstation-2x-x: 0px;
$sprite-packstation-2x-y: 0px;
$sprite-packstation-2x-offset-x: 0px;
$sprite-packstation-2x-offset-y: 0px;
$sprite-packstation-2x-width: 174px;
$sprite-packstation-2x-height: 38px;
$sprite-packstation-2x-total-width: 410px;
$sprite-packstation-2x-total-height: 1454px;
$sprite-packstation-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-packstation-2x: (0px, 0px, 0px, 0px, 174px, 38px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-packstation-2x', );
$sprite-paypal-2x-name: 'sprite-paypal-2x';
$sprite-paypal-2x-x: 0px;
$sprite-paypal-2x-y: 902px;
$sprite-paypal-2x-offset-x: 0px;
$sprite-paypal-2x-offset-y: -902px;
$sprite-paypal-2x-width: 102px;
$sprite-paypal-2x-height: 72px;
$sprite-paypal-2x-total-width: 410px;
$sprite-paypal-2x-total-height: 1454px;
$sprite-paypal-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypal-2x: (0px, 902px, 0px, -902px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-paypal-2x', );
$sprite-paypalcheckout-2x-name: 'sprite-paypalcheckout-2x';
$sprite-paypalcheckout-2x-x: 0px;
$sprite-paypalcheckout-2x-y: 1264px;
$sprite-paypalcheckout-2x-offset-x: 0px;
$sprite-paypalcheckout-2x-offset-y: -1264px;
$sprite-paypalcheckout-2x-width: 410px;
$sprite-paypalcheckout-2x-height: 88px;
$sprite-paypalcheckout-2x-total-width: 410px;
$sprite-paypalcheckout-2x-total-height: 1454px;
$sprite-paypalcheckout-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypalcheckout-2x: (0px, 1264px, 0px, -1264px, 410px, 88px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-paypalcheckout-2x', );
$sprite-paypalexpress-2x-name: 'sprite-paypalexpress-2x';
$sprite-paypalexpress-2x-x: 0px;
$sprite-paypalexpress-2x-y: 1190px;
$sprite-paypalexpress-2x-offset-x: 0px;
$sprite-paypalexpress-2x-offset-y: -1190px;
$sprite-paypalexpress-2x-width: 128px;
$sprite-paypalexpress-2x-height: 74px;
$sprite-paypalexpress-2x-total-width: 410px;
$sprite-paypalexpress-2x-total-height: 1454px;
$sprite-paypalexpress-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-paypalexpress-2x: (0px, 1190px, 0px, -1190px, 128px, 74px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-paypalexpress-2x', );
$sprite-rechnung-2x-name: 'sprite-rechnung-2x';
$sprite-rechnung-2x-x: 0px;
$sprite-rechnung-2x-y: 974px;
$sprite-rechnung-2x-offset-x: 0px;
$sprite-rechnung-2x-offset-y: -974px;
$sprite-rechnung-2x-width: 102px;
$sprite-rechnung-2x-height: 72px;
$sprite-rechnung-2x-total-width: 410px;
$sprite-rechnung-2x-total-height: 1454px;
$sprite-rechnung-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-rechnung-2x: (0px, 974px, 0px, -974px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-rechnung-2x', );
$sprite-sepa-2x-name: 'sprite-sepa-2x';
$sprite-sepa-2x-x: 0px;
$sprite-sepa-2x-y: 1046px;
$sprite-sepa-2x-offset-x: 0px;
$sprite-sepa-2x-offset-y: -1046px;
$sprite-sepa-2x-width: 102px;
$sprite-sepa-2x-height: 72px;
$sprite-sepa-2x-total-width: 410px;
$sprite-sepa-2x-total-height: 1454px;
$sprite-sepa-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-sepa-2x: (0px, 1046px, 0px, -1046px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-sepa-2x', );
$sprite-visa-2x-name: 'sprite-visa-2x';
$sprite-visa-2x-x: 0px;
$sprite-visa-2x-y: 1118px;
$sprite-visa-2x-offset-x: 0px;
$sprite-visa-2x-offset-y: -1118px;
$sprite-visa-2x-width: 102px;
$sprite-visa-2x-height: 72px;
$sprite-visa-2x-total-width: 410px;
$sprite-visa-2x-total-height: 1454px;
$sprite-visa-2x-image: '../staticImages/img-sprite-2x.png';
$sprite-visa-2x: (0px, 1118px, 0px, -1118px, 102px, 72px, 410px, 1454px, '../staticImages/img-sprite-2x.png', 'sprite-visa-2x', );
$spritesheet-width: 205px;
$spritesheet-height: 727px;
$spritesheet-image: '../staticImages/img-sprite.png';
$spritesheet-sprites: ($sprite-amex, $sprite-creditcard, $sprite-creditcards-wide, $sprite-dhl, $sprite-forecast-dhl, $sprite-forecast-dhl-eu, $sprite-forecast-dhl-express, $sprite-forecast-hermes, $sprite-forecast-wein-service-bonn, $sprite-giftvoucher, $sprite-hermes, $sprite-hermeslogo, $sprite-hermespaketshop, $sprite-mastercard, $sprite-packstation, $sprite-paypal, $sprite-paypalcheckout, $sprite-paypalexpress, $sprite-rechnung, $sprite-sepa, $sprite-visa, );
$spritesheet: (205px, 727px, '../staticImages/img-sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 410px;
$retina-spritesheet-height: 1454px;
$retina-spritesheet-image: '../staticImages/img-sprite-2x.png';
$retina-spritesheet-sprites: ($sprite-amex-2x, $sprite-creditcard-2x, $sprite-creditcards-wide-2x, $sprite-dhl-2x, $sprite-forecast-dhl-2x, $sprite-forecast-dhl-eu-2x, $sprite-forecast-dhl-express-2x, $sprite-forecast-hermes-2x, $sprite-forecast-wein-service-bonn-2x, $sprite-giftvoucher-2x, $sprite-hermes-2x, $sprite-hermeslogo-2x, $sprite-hermespaketshop-2x, $sprite-mastercard-2x, $sprite-packstation-2x, $sprite-paypal-2x, $sprite-paypalcheckout-2x, $sprite-paypalexpress-2x, $sprite-rechnung-2x, $sprite-sepa-2x, $sprite-visa-2x, );
$retina-spritesheet: (410px, 1454px, '../staticImages/img-sprite-2x.png', $retina-spritesheet-sprites, );

// These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
//
// The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
$sprite-amex-group-name: 'sprite-amex';
$sprite-amex-group: ('sprite-amex', $sprite-amex, $sprite-amex-2x, );
$sprite-creditcard-group-name: 'sprite-creditcard';
$sprite-creditcard-group: ('sprite-creditcard', $sprite-creditcard, $sprite-creditcard-2x, );
$sprite-creditcards-wide-group-name: 'sprite-creditcards-wide';
$sprite-creditcards-wide-group: ('sprite-creditcards-wide', $sprite-creditcards-wide, $sprite-creditcards-wide-2x, );
$sprite-dhl-group-name: 'sprite-dhl';
$sprite-dhl-group: ('sprite-dhl', $sprite-dhl, $sprite-dhl-2x, );
$sprite-forecast-dhl-group-name: 'sprite-forecast-dhl';
$sprite-forecast-dhl-group: ('sprite-forecast-dhl', $sprite-forecast-dhl, $sprite-forecast-dhl-2x, );
$sprite-forecast-dhl-eu-group-name: 'sprite-forecast-dhl_eu';
$sprite-forecast-dhl-eu-group: ('sprite-forecast-dhl_eu', $sprite-forecast-dhl-eu, $sprite-forecast-dhl-eu-2x, );
$sprite-forecast-dhl-express-group-name: 'sprite-forecast-dhl_express';
$sprite-forecast-dhl-express-group: ('sprite-forecast-dhl_express', $sprite-forecast-dhl-express, $sprite-forecast-dhl-express-2x, );
$sprite-forecast-hermes-group-name: 'sprite-forecast-hermes';
$sprite-forecast-hermes-group: ('sprite-forecast-hermes', $sprite-forecast-hermes, $sprite-forecast-hermes-2x, );
$sprite-forecast-wein-service-bonn-group-name: 'sprite-forecast-wein_service_bonn';
$sprite-forecast-wein-service-bonn-group: ('sprite-forecast-wein_service_bonn', $sprite-forecast-wein-service-bonn, $sprite-forecast-wein-service-bonn-2x, );
$sprite-giftvoucher-group-name: 'sprite-giftvoucher';
$sprite-giftvoucher-group: ('sprite-giftvoucher', $sprite-giftvoucher, $sprite-giftvoucher-2x, );
$sprite-hermes-group-name: 'sprite-hermes';
$sprite-hermes-group: ('sprite-hermes', $sprite-hermes, $sprite-hermes-2x, );
$sprite-hermeslogo-group-name: 'sprite-hermeslogo';
$sprite-hermeslogo-group: ('sprite-hermeslogo', $sprite-hermeslogo, $sprite-hermeslogo-2x, );
$sprite-hermespaketshop-group-name: 'sprite-hermespaketshop';
$sprite-hermespaketshop-group: ('sprite-hermespaketshop', $sprite-hermespaketshop, $sprite-hermespaketshop-2x, );
$sprite-mastercard-group-name: 'sprite-mastercard';
$sprite-mastercard-group: ('sprite-mastercard', $sprite-mastercard, $sprite-mastercard-2x, );
$sprite-packstation-group-name: 'sprite-packstation';
$sprite-packstation-group: ('sprite-packstation', $sprite-packstation, $sprite-packstation-2x, );
$sprite-paypal-group-name: 'sprite-paypal';
$sprite-paypal-group: ('sprite-paypal', $sprite-paypal, $sprite-paypal-2x, );
$sprite-paypalcheckout-group-name: 'sprite-paypalcheckout';
$sprite-paypalcheckout-group: ('sprite-paypalcheckout', $sprite-paypalcheckout, $sprite-paypalcheckout-2x, );
$sprite-paypalexpress-group-name: 'sprite-paypalexpress';
$sprite-paypalexpress-group: ('sprite-paypalexpress', $sprite-paypalexpress, $sprite-paypalexpress-2x, );
$sprite-rechnung-group-name: 'sprite-rechnung';
$sprite-rechnung-group: ('sprite-rechnung', $sprite-rechnung, $sprite-rechnung-2x, );
$sprite-sepa-group-name: 'sprite-sepa';
$sprite-sepa-group: ('sprite-sepa', $sprite-sepa, $sprite-sepa-2x, );
$sprite-visa-group-name: 'sprite-visa';
$sprite-visa-group: ('sprite-visa', $sprite-visa, $sprite-visa-2x, );
$retina-groups: ($sprite-amex-group, $sprite-creditcard-group, $sprite-creditcards-wide-group, $sprite-dhl-group, $sprite-forecast-dhl-group, $sprite-forecast-dhl-eu-group, $sprite-forecast-dhl-express-group, $sprite-forecast-hermes-group, $sprite-forecast-wein-service-bonn-group, $sprite-giftvoucher-group, $sprite-hermes-group, $sprite-hermeslogo-group, $sprite-hermespaketshop-group, $sprite-mastercard-group, $sprite-packstation-group, $sprite-paypal-group, $sprite-paypalcheckout-group, $sprite-paypalexpress-group, $sprite-rechnung-group, $sprite-sepa-group, $sprite-visa-group, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
//   It should be used with a "retina group" variable.
//
// The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
//
// $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );
//
// .icon-home {
//   @include retina-sprite($icon-home-group);
// }
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

// The `retina-sprites` mixin generates a CSS rule and media query for retina groups
//   This yields the same output as CSS retina template but can be overridden in SCSS
//
// @include retina-sprites($retina-groups);
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
