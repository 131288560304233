@import '../../../bps/components/grid/gridElement.scss';

.default-layout {
  .grid {
    &--raster {
      .row.row--default:last-child > div {
        padding-bottom: 30px;

        @media (min-width: $screen-tablet-portrait) {
          padding-bottom: 40px;
        }
      }
    }
  }
}
