@import 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300..700&display=swap';

@font-face {
  font-family: "Noto Sans";
  src:
    local("Noto Sans"),
    url('../fonts/NotoSans-VariableFont.ttf') format('truetype');
  font-style: normal;
  font-weight: 300 700;
  font-display: swap; // stylelint-disable-line plugin/no-unsupported-browser-features
}
